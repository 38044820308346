export const greenStatusColor = "#41B06E";
export const redStatusColor = "#FF7777";


export const get_sector_diagram_config = (doughchartData) => {
    return {
    type: "doughnut",
    data: {
      labels: ["Operative", "Down"],
      datasets: [
        {
          label: "Service Status",
          data: doughchartData,
          backgroundColor: [greenStatusColor, redStatusColor],
        },
      ],
    },
    options: {
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
          border: {
            display: false,
          },
        },
      },
    },
  }
}
