import { Box } from "@mui/material";
import { Chart } from "chart.js";
import { useEffect, useState } from "react";


export default function MiniChart(data: any) {
    const [dataSeries, setDataSeries] = useState([]);
    let chartRef: Chart | null = null;
  
    function incrementChart(tempSeries: any) {
      if (chartRef) {
        const dataset = chartRef.data.datasets[0];
        dataset.data = new Array(tempSeries.length).fill(0);
  
        chartRef?.update();
      }
    }
  
    useEffect(() => {
      const tempSeries = data.series;
      incrementChart(tempSeries);
      setDataSeries(tempSeries);
    }, [data]);
  
    function getChartData(series: any) {
      const data_series = series?.length > 0 ? series : [0, 0]
      return {
        labels: new Array(data_series.length).fill(0),
        datasets: [
          {
            data: data_series,
            pointRadius: 0,
            fill: false,
            borderWidth: 1.5,
            tension: 0.1,
          },
        ],
      };
    }
  
    useEffect(() => {
      if (chartRef == null) {
        const ctx: any = document.getElementById("mini_chart_" + data.count);
        const config: any = {
          type: "line",
          data: getChartData(dataSeries),
          options: {
            interaction: {
              mode: false, // Disables interaction mode
            },
            hover: {
              mode: null, // Disable hover
            },
            animation: false,
            layout: {
              padding: 0,
            },
            responsive: false,
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
                border: {
                  display: false,
                },
              },
              y: {
                min: 0,
                beginAtZero: true, // Ensures the y-axis starts at 0
                grid: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
                border: {
                  display: false,
                },
              },
            },
            plugins: {
              tooltip: {
                padding: 0,
                enabled: false,
              },
              legend: {
                display: false, // This hides the legend
              },
              title: {
                display: false,
              },
            },
          },
        };
        chartRef = new Chart(ctx, config);
        return () => {
          chartRef?.destroy();
        };
      }
    }, [dataSeries]);
  
    return (
      <Box sx={{display:"flex", justifyContent:"flex-end", }}>
        <canvas
          id={"mini_chart_" + data.count}
          style={{ height: "4rem", maxWidth: "15rem" }}
        />
      </Box>
    );
  }
  