import { Container, Grid } from "@mui/material";

export default function Footer() {
    const wrapper = {
      width: "100%",
      padding: "1rem 0 2rem 0",
      backgroundColor: "black",
      marginTop: "1rem",
    };
    return (
      <div style={wrapper}>
        <Container>
          <Grid
            container
            alignContent={"center"}
            justifyContent={"center"}
            spacing={2}
          >
            <Grid item>
              <div
                style={{
                  color: "white",
                  marginTop: "0.5rem",
                  fontSize: "0.8rem",
                }}
              >
                POWERED BY
              </div>
            </Grid>
            <Grid item>
              <img src={"/static/it_logo.png"} height="30px" alt="itlogo" />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }