import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Api from "../../api";
import UserContext from "../../context/userContext";
import { useNavigate } from "react-router-dom";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Login() {

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setUserData } = useContext(UserContext);
  const navigate = useNavigate();

  window.location.replace("/account/login/");

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await Api.get("/auth");
        setUserData(res);
        navigate("/");

      } catch (e) {
      }
    };
    getUser();
  }, []);


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const form = {
      username: data.get("username"),
      password: data.get("password"),
    };
    try {
      const res = await Api.post("/auth", form);
      navigate("/");
      setUserData(res);
    } catch (e) {
      setError(true);
    }
    setIsLoading(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ mb: 2 }}>
            <img
              src={`/static/itno_logo.png`}
              height="50"
              alt="itlogo"
            />
          </Box>
          <Typography component="h1" variant="h5">
            Network Dashboard
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 2 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              disabled={isLoading}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 1 }}
            >
              Sign in
            </Button>
          </Box>
        </Box>
        {error && (
          <Typography variant="body2" color="error" align="center">
            User not found with the given credentials with company assignment and login privileges.
          </Typography>
        )}
      </Container>
    </ThemeProvider>
  );
}
