import {
  Container,
  Grid,
  Button,
  styled,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Api from "../../../api";
import {
  useLoaderData,
  useLocation,
  useParams,
  useRevalidator,
} from "react-router-dom";
import style from "./SiteFilesPage.module.css";
import { useContext, useEffect, useState } from "react";
import { ISiteFile } from "../../../interface";
import SiteContext from "../../../context/siteContext";
import dayjs from "dayjs";
import DescriptionIcon from "@mui/icons-material/Description";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function SiteFilesPage(props: any) {
  const { dashboardData } = useContext(SiteContext);

  const revalidator = useRevalidator();
  const [deleteFile, setDeleteFile] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();
  async function handleUpload(event: any) {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    await Api.upload(`/sites/${params?.id}/upload/`, formData);
    revalidator.revalidate();
  }

  async function handleDelete(id: number) {
    const data = {
      file_id: id,
    };
    setIsLoading(true);
    const res = await Api.delete(`/sites/${params?.id}/upload/`, data);
    setIsLoading(false);

    setDeleteFile(null);
    revalidator.revalidate();
  }

  function handleClose() {
    setDeleteFile(null);
  }

  function handleOpen(file: any) {
    if (file?.file) {
      const filepath = file.file as string;
      window.open(filepath, "_blank")?.focus();
    }
  }

  const files = dashboardData?.location?.files;
  return (
    <>
      <Dialog
        open={deleteFile !== null}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Confirm delete file"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm delete {deleteFile?.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => handleDelete(deleteFile.id)}
            autoFocus
            disabled={isLoading}
          >
            Yes delete
          </Button>
        </DialogActions>
      </Dialog>

      <Container
        maxWidth="xl"
        sx={{   }}
      >
        <div style={{backgroundColor:"white", padding:"1rem", borderRadius: "0.5rem"}}>
        <Grid
          container
          style={{}}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Typography variant="h6">File Management</Typography>
          </Grid>
          <Grid item>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              disabled={isLoading}
              startIcon={<CloudUploadIcon />}
            >
              Upload
              <VisuallyHiddenInput
                type="file"
                onChange={handleUpload}
                multiple
              />
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          {files?.length === 0 ? (
            <Grid container justifyContent={"center"}>
              <Grid item sx={{ p: 3 }}>
                No files attached to this site
              </Grid>
            </Grid>
          ) : (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Filename</TableCell>
                    <TableCell>Date Uploaded</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files?.map((file: ISiteFile) => (
                    <TableRow>
                      <TableCell>{file.name}</TableCell>

                      <TableCell>
                        {dayjs(file.uploaded_at).format("HH:mm - MMM D, YYYY")}
                      </TableCell>
                      <TableCell>
                        <Button
                          startIcon={<OpenInNewIcon />}
                          color="primary"
                          onClick={() => handleOpen(file)}
                        >
                          Open
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={() => setDeleteFile(file)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        </div>
      </Container>
    </>
  );
}
