import { useContext, useEffect, useState } from "react";
import Api from "../../../api";
import {
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Container,
  Stack,
  Switch,
  FormControlLabel,
} from "@mui/material";

import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import RestartAltTwoToneIcon from "@mui/icons-material/RestartAltTwoTone";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import "leaflet/dist/leaflet.css";
import "leaflet-providers/leaflet-providers";
import GaugeComponent from "react-gauge-component";
import _ from "lodash";
import dayjs from "dayjs";
import L from "leaflet";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import SiteContext from "../../../context/siteContext";
import { IFirewall } from "../../../interface";
import RunToolsModal from "./RunToolsModal";
import { Chart } from "chart.js";
import { RebootModal } from "./RebootModal";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

export function siteFirewallLoader(request: any, url: string) {
  const { params } = request;
  return Api.get(`/${url}/?site=${params?.id}`);
}

export function MetricTile(props: any) {
  let { label, value, size } = props;
  if (!size) {
    size = 4;
  }

  return (
    <Grid item sm={size} sx={{ padding: "0.4rem", marginBottom: "0.5rem" }}>
      <Typography sx={{ color: "grey" }}>{label}</Typography>
      <Typography>{value} </Typography>
    </Grid>
  );
}

function ClientList(props: any) {
  const { firewall } = props;
  return (
    <Grid item xs={12} sx={{ marginTop: "0rem" }}>
      <div>
        <Typography variant="h6" style={{ padding: "1rem 0rem 0rem 1rem" }}>
          DHCP Leases
        </Typography>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>IP Address</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Mac Address</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Hostname</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Start</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>End</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {firewall?.dhcp_leases_in_prod_network.map((row: any) => (
                <TableRow
                  key={row.mac}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.ip}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.mac}
                  </TableCell>
                  <TableCell>{row.hostname}</TableCell>
                  <TableCell>{row.start}</TableCell>
                  <TableCell>{row.end}</TableCell>
                  <TableCell>
                    {row.binding}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Grid>
  );
}

function generateEqualNumbers(x:number) {
  const numTicks = 5;
  const step = x / (numTicks - 1);
  let ticks = []; 
  for (let i = 0; i < numTicks; i++) {
      ticks.push(Math.round(i * step));
  }
  return ticks;
}


export default function SiteFirewallPage() {
  const { dashboardData } = useContext(SiteContext);
  const [activeFirewall, setFirewall] = useState<IFirewall>();
  const [showToolsModal, setShowToolsModal] = useState<boolean>(false);
  const [showRebootModal, setShowRebootModal] = useState<boolean>(false);
  const [gaugeValue, setGaugeValue] = useState(0);
  const [bandwithHosts, setBandwithHosts] = useState([]);
  const [bandwidthData, setBandwidthData] = useState<any>();
  const [showBandwidthPeak, setShowBandwidthPeak] = useState(false);

  const [gaugeMaxValue, setGaugeMaxValue] = useState(300)
  const [worldMap, setWorldMap] = useState<L.Map | null>(null);
  const [hasRenderedCountryLines, setHasRenderedCountryLines] = useState(false);
  const [worldMapTableRows, setWorldMapTable] = useState<any>([]);
  const [blockedTrafficCount, setBlockedTrafficCount] = useState(0);

  async function fetchBandwidth() {
    const res = await Api.get(
      `/firewalls/bandwidth/?firewall=${activeFirewall?.serial_number}`
    );
    setBandwidthData(res)
  }

  useEffect(() => {
    if(bandwidthData == null) return;

    if(showBandwidthPeak){
      setGaugeValue(_.get(bandwidthData, "max.transfer_mbps", 0))
      setBandwithHosts(_.get(bandwidthData, "max.data.hosts", []))
    }else {
      if(bandwidthData.is_operative){
        setGaugeValue(_.get(bandwidthData, "recent.transfer_mbps", 0))
        setBandwithHosts(_.get(bandwidthData, "recent.data.hosts", []))  
      }else {
        setGaugeValue(0)
        setBandwithHosts([])  
      }
    }

  }, [showBandwidthPeak, bandwidthData])

  useEffect(() => {
    if (activeFirewall?.serial_number !== undefined) {
      fetchBandwidth();
    }

    const intervalId = setInterval(() => {
      if (activeFirewall !== null) {
        fetchBandwidth();
      }
    }, 15000);

    return () => clearInterval(intervalId);
  }, [activeFirewall?.serial_number]);

  useEffect(() => {
    const firewalls = dashboardData?.firewalls?.instances;
    if (firewalls?.length > 0) {
      setFirewall(firewalls[0]);
    }
    const bandwith_field = _.find(dashboardData?.location.extra_fields, {slug: 'bandwidth'});
    let max = 300;
    if(bandwith_field){
      let temp = parseInt(bandwith_field?.value)
      if(temp){
        max = temp;
      }
    }
    if (max > 66){ // gauge cant have a lower number than 60
      setGaugeMaxValue(max);
    }
  }, [dashboardData]);

  useEffect(() => {
    const map = L.map("world_map", {
      attributionControl: false,
    }).setView([40, 0], 1.5);
    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      noWrap: true,
    }).addTo(map);
    map.scrollWheelZoom.disable();

    setWorldMap(map);

    return () => {
      setWorldMap(null);
      map.remove();
    };
  }, []);

  useEffect(() => {
    let blockedTraffic = activeFirewall?.country_blocks_7_days;

    if (blockedTraffic) {
      let itms = [];
      for (let [key, value] of Object.entries(blockedTraffic)) {
        itms.push({
          country: key,
          count: value,
        });
      }
      itms.sort((a: any, b: any) => b.count - a.count);
      setWorldMapTable(itms);
      let values = Object.values(blockedTraffic);
      let valueCounts = _.sumBy(values);
      setBlockedTrafficCount(valueCounts);
    }
  }, [activeFirewall]);

  useEffect(() => {
    if (worldMap == null) {
      return;
    }

    function getColor(d: any) {
      return d > 10000
        ? "#800026"
        : d > 7000
        ? "#BD0026"
        : d > 4000
        ? "#E31A1C"
        : d > 2000
        ? "#FC4E2A"
        : d > 1000
        ? "#FD8D3C"
        : d > 500
        ? "#FEB24C"
        : d > 0
        ? "#FED976"
        : "#FFEDA0";
    }

    function style(feature: any) {
      return {
        fillColor: getColor(feature.properties.density),
        weight: 1,
        opacity: 1,
        color: "white",
        dashArray: "3",
        fillOpacity: 0.7,
      };
    }

    fetch("/static/country_borders.json")
      .then(function (response) {
        return response.json();
      })
      .then(function (geojson) {
        const blocked_traffic = activeFirewall?.country_blocks_7_days ?? {};
        const features = geojson.features;
        _.map(features, (item) => {
          let density = 0;
          const country = item.properties.country;
          if (country in blocked_traffic) {
            density = blocked_traffic[country];
          }
          item.properties.density = density;
        });
        L.geoJSON(geojson, {
          style: style,
        }).addTo(worldMap);
      })
      .catch(function (error) {
        console.error("Error loading the GeoJSON data:", error);
      });
    setHasRenderedCountryLines(true);
  }, [activeFirewall]);

  useEffect(() => {
    if (activeFirewall == null) return;
    const labels = activeFirewall.traffic_data.map((x: any) =>
      dayjs(new Date(x.timestamp)).format("HH:mm")
    );
    const inbound = activeFirewall.traffic_data.map((x: any) => x.inblock);
    const outbound = activeFirewall.traffic_data.map((x: any) => x.outblock);
    const config: any = {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Blocked Traffic Inbound",
            data: inbound,
            pointRadius: 0,
            fill: true,
            borderColor: "rgba(228, 139, 74, 1)",
            borderWidth: 1,
            backgroundColor: "rgba(228, 139, 74, 0.2)",
          },
          {
            label: "Blocked Traffic Outbound",
            data: outbound,
            pointRadius: 0,
            fill: true,
            borderColor: "rgba(255, 214, 112, 1)",
            borderWidth: 1,
            backgroundColor: "rgba(255, 214, 112, 0.2)",
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Bits per second",
            },
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: false,
          },
        },
      },
    };
    const ctx: any = document.getElementById("blocked_traffic_chart");

    const chart = new Chart(ctx, config);
    return () => {
      chart.destroy();
    };
  }, [activeFirewall]);

  useEffect(() => {
    if (activeFirewall == null) return;
    const labels = activeFirewall.traffic_data.map((x: any) =>
      dayjs(new Date(x.timestamp)).format("HH:mm")
    );
    const inbound = activeFirewall.traffic_data.map((x: any) => x.inpass);
    const outbound = activeFirewall.traffic_data.map((x: any) => x.outpass);

    const config: any = {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: "WAN Traffic Inbound",
            data: inbound,
            pointRadius: 0,
            fill: true,
            borderColor: "rgba(115, 191, 105, 1)",
            borderWidth: 1,
            backgroundColor: "rgba(115, 191, 105, 0.2)",
          },
          {
            label: "WAN Traffic Outbound",
            data: outbound,
            pointRadius: 0,
            fill: true,
            borderColor: "rgba(228, 139, 74, 1)",
            borderWidth: 1,
            backgroundColor: "rgba(228, 139, 74, 0.2)",
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Bits per second",
            },
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: false,
          },
        },
      },
    };
    const ctx: any = document.getElementById("traffic_data_chart");

    const chart = new Chart(ctx, config);
    return () => {
      chart.destroy();
    };
  }, [activeFirewall]);

  useEffect(() => {
    /*
    if (activeFirewall == null) return;
    const traffic_reversed = activeFirewall.traffic_data.reverse();
    const labels = traffic_reversed.map((x: any) =>
      dayjs(new Date(x.timestamp)).format("HH:mm")
    );
    const inbound = activeFirewall.processor_data.map((x: any) => x.system);
    const ctx: any = document.getElementById("processor_usage_chart");
    const config: any = {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            data: inbound,
            pointRadius: 0,
            fill: false,
            borderWidth: 1,
            borderColor: "rgba(108, 122, 137, 1)",
            tension: 0.1,
          },
        ],
      },
      options: {
        layout: {
          padding: 0,
        },
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            min: 0,
            beginAtZero: true, // Ensures the y-axis starts at 0
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
            border: {
              display: false,
            },
          },
        },
        plugins: {
          tooltip: {
            padding: 0,
          },
          legend: {
            display: false, // This hides the legend
          },
          title: {
            display: false,
          },
        },
      },
    };
    const chart = new Chart(ctx, config);
    return () => {
      chart.destroy();
    };
    */
  }, [activeFirewall]);

  return (
    <>
      {activeFirewall && (
        <>
          <RunToolsModal
            show={showToolsModal}
            setShow={setShowToolsModal}
            firewall={activeFirewall}
          />
          <RebootModal
            show={showRebootModal}
            setShow={setShowRebootModal}
            firewall={activeFirewall}
          />
        </>
      )}
      <Container maxWidth="xl">
        <Grid container style={{}} spacing="2">
          <Grid
            item
            style={{
              width: "100%",
            }}
          >
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Box
                  sx={{
                    backgroundColor: "white",
                    height:"100%"
                  }}
                >
                  <Box sx={{ padding: "0.5rem" }}>
                    <FormControl fullWidth variant="standard" sx={{}}>
                      <InputLabel id="demo-simple-select-label">
                        Firewall
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={activeFirewall?.serial_number ?? ""}
                        label="Firewall Name"
                        onChange={(event) => {
                          const result = _.find(
                            dashboardData?.firewalls?.instances,
                            { serial_number: event.target.value }
                          );
                          setFirewall(result);
                        }}
                      >
                        {dashboardData?.firewalls?.instances.map(
                          (firewall: IFirewall) => (
                            <MenuItem
                              key={firewall.serial_number}
                              value={firewall.serial_number}
                            >
                              {firewall.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                  <Grid
                    container
                    sx={{ marginTop: "0.5rem", padding: "0.5rem" }}
                    spacing={0}
                  >
                    <MetricTile
                      key={1}
                      label={"SERIAL NUMBER"}
                      value={activeFirewall?.serial_number}
                    />
                    <MetricTile
                      key={2}
                      label={"MAC ADDRESS"}
                      value={activeFirewall?.mac}
                    />
                    <MetricTile
                      key={3}
                      label={"UPTIME"}
                      value={activeFirewall?.extra_data?.system_uptime_format}
                    />
                    <MetricTile
                      key={4}
                      label={"HOSTNAME"}
                      value={activeFirewall?.hostname}
                    />
                    <MetricTile
                      key={5}
                      label={"WAN"}
                      value={activeFirewall?.wan}
                    />
                    <MetricTile
                      key={6}
                      label={"IP ADDRESS"}
                      value={activeFirewall?.ip_address}
                    />
                  </Grid>
                </Box>
              </Grid>

              <Grid item sm={4.5}>
                <Grid
                  container
                  style={{
                    backgroundColor: "white",
                    height: "100%",
                  }}
                >
                  <Grid item md={6} sx={{ height: "100%" }}>
                    <Grid
                      container
                      direction={"column"}
                      justifyContent={"center"}
                      sx={{ height: "100%" }}
                    >
                      <Grid item>
                        <GaugeComponent
                          arc={{
                            nbSubArcs: 150,
                            colorArray: ["#5BE12C", "#F5CD19", "#f4a093"],
                            width: 0.3,
                            padding: 0.003,
                          }}
                          value={gaugeValue}
                          labels={{
                            valueLabel: {
                              formatTextValue: (value) => value + " Mbps",
                              style: {
                                fill: "grey",
                                textShadow: "0",
                                width: "100%",
                              },
                            },
                            tickLabels: {
                              type: "outer",
                              defaultTickValueConfig: {
                                formatTextValue: (value: any) =>
                                  value + " Mbps",
                              },
                              ticks: generateEqualNumbers(gaugeMaxValue).map((val) => {return {value: val}})
                            },
                          }}
                          maxValue={gaugeMaxValue}
                        />
                        <Stack direction="row" justifyContent={"center"}>
                          <Typography variant="body1" color="text.secondary">Bandwidth</Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent={"center"}
                          sx={{  pt:1 }}
                        >
                          <Typography variant="caption" color="text.secondary">
                            Now
                          </Typography>
                          <Switch size="small" checked={showBandwidthPeak} onChange={(e,val) => setShowBandwidthPeak(val)} />
                          <Typography variant="caption" color="text.secondary">
                            Peak
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6} sx={{
                          maxHeight:"15rem",
                          overflowY:"scroll",
                        }}>

                    <TableContainer>
                      <Table
                        size="small"
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow
                            sx={{ borderBottom: "2px lightgrey solid" }}
                          >
                            <TableCell
                              sx={{ fontWeight: 600, fontSize: "0.8rem" }}
                            >
                              IP
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: 600, fontSize: "0.8rem" }}
                            >
                              Mbps
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody >
                          
                          {bandwithHosts?.map((row: string, i) => {
                            const row_split = row.split(",");
                            const ip = row_split[0];
                            const transfer = row_split[3];
                            return (
                              <TableRow
                                key={i}
                                sx={{
                                  borderBottom:
                                    i % 2 === 1 ? "1px lightgrey solid" : "",
                                }}
                              >
                                <TableCell sx={{ fontSize: "0.7rem" }}>
                                  {ip}
                                </TableCell>
                                <TableCell sx={{ fontSize: "0.7rem" }}>
                                  <Stack
                                    alignItems="center"
                                    direction="row"
                                    gap={1}
                                  >
                                    {i % 2 === 0 ? (
                                      <ArrowRightAltIcon fontSize="small" />
                                    ) : (
                                      <ArrowRightAltIcon
                                        sx={{
                                          transform: "rotate(180deg)",
                                        }}
                                        fontSize="small"
                                      />
                                    )}
                                    <Typography variant="caption">
                                      {transfer}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sm={1.5}>
                <Grid
                  container
                  direction={"column"}
                  justifyContent={"space-around"}
                  style={{
                    textAlign: "center",
                    backgroundColor: "white",
                    height: "100%",
                  }}
                >
                  <Grid
                    container
                    direction={"column"}
                    justifyContent={"space-around"}
                    style={{
                      textAlign: "center",
                      backgroundColor: "white",
                      height: "100%",
                    }}
                  >
                    <Grid item>
                      <div style={{ textAlign: "center" }}>
                        <Typography variant="body1" color="text.secondary">Actions</Typography>
                      </div>
                    </Grid>
                    <Grid>
                      <Button
                        variant="text"
                        style={{ color: "black" }}
                        startIcon={<NetworkCheckIcon fontSize="large" />}
                        onClick={() => setShowToolsModal(true)}
                      >
                        Ping{" "}
                      </Button>
                    </Grid>
                    <Grid>
                      <Button
                        variant="text"
                        style={{ color: "black" }}
                        startIcon={<RestartAltTwoToneIcon fontSize="large" />}
                        onClick={() => setShowRebootModal(true)}
                      >
                        Reboot{" "}
                      </Button>
                    </Grid>
                    <Grid>
                      <Button
                        variant="text"
                        style={{
                          color:
                            activeFirewall?.admin_url != null
                              ? "black"
                              : "grey",
                        }}
                        onClick={() =>
                          window.open(activeFirewall?.admin_url!, "_blank")
                        }
                        disabled={activeFirewall?.admin_url == null}
                        startIcon={<OpenInNewIcon fontSize="large" />}
                      >
                        Admin{" "}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ marginTop: "0rem" }}>
          <Grid item md={6}>
            <div style={{ backgroundColor: "white", padding: "1rem" }}>
              <canvas id="traffic_data_chart" width={"100%"} height={32} />
            </div>
          </Grid>
          <Grid item md={6}>
            <div style={{ backgroundColor: "white", padding: "1rem" }}>
              <canvas id="blocked_traffic_chart" width={"100%"} height={32} />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: "0rem" }}>
          <Grid item md={1.5}>
            <Grid
              container
              direction={"column"}
              justifyContent={"space-evenly"}
              style={{
                textAlign: "center",
                backgroundColor: "white",
                height: "100%",
              }}
            >
              <Grid item>
                <Typography variant="body1">Blocked Traffic</Typography>
                <Typography variant="h3" color="rgba(228, 139, 74, 1)">
                  {blockedTrafficCount > 1000 ? Math.trunc(blockedTrafficCount / 1000) + "k" : blockedTrafficCount}
                </Typography>
                <Typography variant="body2" color={"text.Secondary"}>
                  Last 7 days
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2.5}>
            <Box
              sx={{
                textAlign: "center",
                backgroundColor: "white",
                height: "25rem",
                overflowY: "scroll",
              }}
            >
              <TableContainer>
                <Table size="small" sx={{ marginTop: "0.3rem" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Country</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {worldMapTableRows.map((row: any, i: number) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{row.country}</TableCell>
                        <TableCell>{row.count}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ pt: 1 }}
              ></Typography>
            </Box>
          </Grid>

          <Grid item md={8}>
            <div id="world_map" style={{ height: "25rem" }}></div>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: "1rem" }}>
          <Grid item md={12}>
            <div style={{ backgroundColor: "white" }}>
              <ClientList key={"clientlist"} firewall={activeFirewall} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
